// Copied from erpnext/e_commerce/product_ui/views.js
frappe.provide("vef")

vef.PlansView = class {
    constructor(options) {
        Object.assign(this, options);
        this.preference = this.interval;
        this.make();
    }

    make() {
        this.plans_section.empty();
        // this.prepare_toolbar();
        this.get_item_filter_data();
    }

    prepare_toolbar() {
        this.plans_section.append(`
			<div class="billing-bar d-flex justify-content-center">
			</div>
		`);

        this.prepare_view_toggler();
    }

    prepare_view_toggler() {
        if (!$("#monthly").length || !$("#annually").length) {
            this.render_view_toggler();
            this.bind_view_toggler_actions();
            this.set_view_state();
        }
    }

    get_item_filter_data() {
        let me = this;

        this.disable_view_toggler(true);

        frappe.call({
            method: "vef_cloud.api.cloud.get_plans",
            callback: function (result) {
                if (!result || result.exc || !result.message || result.message.exc) {
                    me.render_no_plans_section(true);
                } else {
                    console.log(result.message)
                    if (!result.message) {
                        // if result has no items or result is empty
                        me.render_no_plans_section();
                    } else {
                        // Render views
                        console.log("render")
                        // me.render_monthly_plans(result.message);
                        me.render_annual_plans(result.message);

                        me.plans = result.message;
                        console.log(me.plans)
                    }
                }

                me.disable_view_toggler(false);
            }
        });
    }

    disable_view_toggler(disable = false) {
        $('#monthly').prop('disabled', disable);
        $('#annually').prop('disabled', disable);
    }

    render_monthly_plans(items) {
        let me = this;
        this.prepare_plan_area_wrapper("monthly");

        new vef.Plans({
            items: items,
            plans_section: $("#monthly-plan-area"),
            preference: "monthly"
        });
    }

    render_annual_plans(items) {
        let me = this;
        this.prepare_plan_area_wrapper("annually");

        new vef.Plans({
            items: items,
            plans_section: $("#annually-plan-area"),
            preference: "annually"
        });
    }

    prepare_plan_area_wrapper(interval) {
        return this.plans_section.append(`
			<div id="${interval}-plan-area" class="row plans-list justify-content-center"></div>
		`);
    }

    render_view_toggler() {
        $(".billing-bar").append(`<div class="toggle-container col-4 p-0 btn-group"></div>`);

        $(".toggle-container").append(`
            <button id="monthly" class="btn btn-monthly" style="width:2em">
                <span> ${__("Monthly")} </span>
            </button>
        `);
        $(".toggle-container").append(`
            <button id="annually" class="btn btn-annually" style="width:2em">
                <span> ${__("Yearly")} </span>
            </button>
        `);
    }

    bind_view_toggler_actions() {
        $("#monthly").click(function () {
            let $btn = $(this);
            $btn.removeClass('btn-primary');
            $btn.addClass('btn-primary');
            $(".btn-annually").removeClass('btn-primary');

            $("#annually-plan-area").addClass("hidden");
            $("#monthly-plan-area").removeClass("hidden");

            localStorage.setItem("billing_interval", "monthly");
        });

        $("#annually").click(function () {
            let $btn = $(this);
            $btn.removeClass('btn-primary');
            $btn.addClass('btn-primary');
            $(".btn-monthly").removeClass('btn-primary');

            $("#monthly-plan-area").addClass("hidden");
            $("#annually-plan-area").removeClass("hidden");

            localStorage.setItem("billing_interval", "annually");
        });
    }

    set_view_state() {
        if (this.preference === "monthly") {
            $("#monthly").addClass('btn-primary');
            $("#annually").removeClass('btn-primary');
        } else {
            $("#annually").addClass('btn-primary');
            $("#monthly").removeClass('btn-primary');
        }
    }

    render_no_plans_section(error = false) {
        return
        let error_section = `
			<div class="mt-4 w-100 alert alert-error font-md">
				Something went wrong. Please refresh or contact us.
			</div>
		`;
        let no_results_section = `
			<div class="cart-empty frappe-card mt-4">
				<div class="cart-empty-state">
					<img src="/assets/erpnext/images/ui-states/cart-empty-state.png" alt="Empty Cart">
				</div>
				<div class="cart-empty-message mt-4">${__('No products found')}</p>
			</div>
		`;

        this.plans_section.append(error ? error_section : no_results_section);
    }
};

vef.Plans = class {
    constructor(options) {
        Object.assign(this, options);

        if (this.preference !== "annually") {
            this.plans_section.addClass("hidden")
        }

        this.plans_section.empty();
        this.make();
    }

    make() {
        let me = this;
        let html = ``;

        for (const [item_code, item] of Object.entries(this.items)) {
            console.log(item)
            let title = item.plan_name || "";
            title = title.length > 90 ? title.substr(0, 90) + "..." : title;

            html += `<div class="col-sm-4 item-card"><div class="card text-left">`;
            html += me.get_card_body_html(item, title);
            html += `</div></div>`;
        }

        let $product_wrapper = this.plans_section;
        $product_wrapper.append(html);

        console.log(html)
        this.bind_cta_button();
    }

    get_card_body_html(item, title, settings) {
        let body_html = `
			<div class="card-body text-left card-body-flex" style="width:100%">
				<div style="display: flex;">
		`;
        body_html += this.get_title(item, title);
        body_html += `</div>`;

        body_html += `<div class="product-category">${item.plan_description || ''}</div>`;


        if (item.plan_billings) {
            let billing = item.plan_billings?.[this.preference]

            console.log(this.preference)
            console.log(billing)
            if (billing) {
                body_html += this.get_price_html(item, billing);
            }

        }


        body_html += `<div class="product-features">`;
        item.plan_features?.map(feat => {
            if (feat.title) {
                body_html += `<div class="product-feature flex flex-row row">
                <span class="d-flex align-items-center justify-content-center col-1 fa fa-${feat.icon || "check"} p-0"></span>
                <span class="col-11 p-0 pl-1">${feat.title || ""}</span>
                </div>`;
            }
        })
        body_html += `</div>`;

        body_html += this.get_primary_button(item);
        body_html += `</div>`; // close div on line 49

        return body_html;
    }

    get_title(item, title) {
        let title_html = `
            <div class="card-title h2 mt-0">
                ${title || ''}
            </div>
		`;
        return title_html;
    }

    get_price_html(item, price) {
        console.log(JSON.stringify(price))
        let price_html = `<div class="product-price price-month">`

        price_html += `${price.price_per_month || price.price || ''}`

        price_html += `<small class="price-interval">
                    /${__("month")}
                </small>
        `;
        price_html += `</div>`;

        if (price.price_per_month) {
            price_html += `<small class="price-annually-message">
                        ${__("billed annually at {0}", [price.price])}
                    </small>
            `;
        }

        //     <small class="striked-price">
        //         <s>${ "Rp100.000" ? "Rp100.000".replace(/ +/g, "") : "" }</s>
        //     </small>
        // `;
        return price_html;
    }

    get_primary_button(item) {
        let plan = item.plan_billings?.[this.preference].name

        return `
            <div id="${plan}" class="btn
                btn-sm btn-primary btn-action w-100 mt-2"
                data-item-code="${plan}">
                ${'Get started'}
            </div>
        `;
    }

    bind_cta_button() {
        $(`.btn-action`).click(function (e) {
            let plan = $(this).attr("data-item-code")

            localStorage.setItem("plan", plan)
            window.location.href = "/register?plan=" + plan
        })
    }
};